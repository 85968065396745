import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>For the uninitiated,</h2>
        <i>                  
          the world of automobiles and its colloquialisms <br/>
          can often be puzzling. While the automotive <br/>
          industry’s jargon is vast and seemingly unending, <br/>
          we hope to acquaint you with the most imperative <br/>
          terms in reference to car types with this piece.
        </i>
      </header>

      <div className="box alt container">
        <section className="feature left">
          <a href="/#" className="image icon fa-repeat">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h3>We visited a Vulcanizing Shop in Panay</h3>
            <p>
              During our stay in the Philippines, we drove around on the island of Panay. 
              On going out from the city limits, with direction into the mountains, our car 
              needed some simple air in the tires.<br/>
              <Link to="/we-visited-a-vulcanizing-shop-in-panay">Read More</Link>
            </p>
          </div>
        </section>
        <section className="feature right">
          <a href="/#" className="image icon fa-repeat">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h3>We visited a Car Dealer</h3>
            <p>
              Buying a car from Fast Track and dealing with the owner Sheila is fun, but we 
              didn’t buy the car. Price started at 320.000 peso for an old 2000 Toyota Hilux 
              4×4 Special Edition, and we managed to secure a 22% discount on the price, <br/>
              <Link to="/we-visited-a-car-dealer">Read More</Link>
            </p>
          </div>
        </section>
        <section className="feature left">
          <a href="/#" className="image icon fa-repeat">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h3>We visited a workshop</h3>
            <p>
              On our stay over in Hong Kong we were out looking for a place to stay and set up our 
              office, the one we have now is too small, and on walking up the hills we where all of 
              a sudden in car repair valley. 5-10 small workshops, <br/>
              <Link to="/we-visited-a-workshop">Read More</Link>
            </p>
          </div>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Contact Us for more information</h3>
        <p>
          Car collectors choice aim to share the knowledge and experience in an online world. We offer design and build services for you to have an idea.
          Feel free to contact us and we will get back to you as soon as it possible.
        </p>
        <ul className="actions special">
          <li>
            <a href="mailto:support@sal-tech.com?Subject=Carcollectorschoice%20Inquiry" className="button" target="_top">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
